<template>
  <div class="search-bar">
    <FormKit
      type="form"
      :actions="false"
      form-class="row px-2 px-lg-0"
      @submit="handleSubmit"
    >
      <FormKit
        id="search-box-value"
        v-model="searchInputValue"
        type="search"
        name="search-value"
        autocomplete="off"
        outer-class="fk-search-bar"
        :floating-label="false"
        :placeholder="
          $textDictionary['searchSection.searchResults.searchPlaceholder'] ?? ''
        "
        :label="
          $textDictionary['searchSection.searchResults.searchPlaceholder'] ?? ''
        "
      >
        <template #suffix>
          <button type="submit" class="fk-search-bar__submit" tabindex="-1">
            <FormKitIcon icon="search" />
          </button>
        </template>
      </FormKit>
    </FormKit>
  </div>
</template>

<script lang="ts" setup>
import { FormKitIcon } from '@formkit/vue';
import type { ITechnicalComponent } from '~/lib/ContentfulService';
import type { UseGlobalSearchOptions } from '~/composables/useGlobalSearch';
import { useGlobalSearch } from '~/composables/useGlobalSearch';

type Props = {
  item: ITechnicalComponent;
};

const props = defineProps<Props>();

const { $textDictionary } = useNuxtApp();

const configuration = computed<UseGlobalSearchOptions>(
  () => props.item.fields.configuration ?? {}
);

const { handleSubmit, searchInputValue } = useGlobalSearch(configuration);
</script>
